import { Component } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-savings-widget',
  templateUrl: './savings-widget.component.html',
  styleUrl: './savings-widget.component.scss',
})
export class SavingsWidgetComponent {
  screenSize = ScreenType;
}
