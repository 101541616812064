import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberService } from '@fgb/core';
import { Regex } from '../../../../shared/utilities/regex';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'fgb-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit {
  formReset: UntypedFormGroup;
  contentModal: string;
  @ViewChild('alert') alert: TemplateRef<any>;
  constructor(private _fb: UntypedFormBuilder, private memberService: MemberService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formReset = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(Regex.email)]],
    });
  }

  submit() {
    if (this.formReset.invalid) return;
    const email = this.formReset.get('email')?.value;
    const clubId = 1;
    this.memberService
      .resetPassword(email, clubId)
      .then((_) => {
        this.openModal('Check your email to change password!');
      })
      .catch((err) => {
        const error = err.error.Message ? err.error.Message : 'System error, please try again later';
        this.openModal(error);
      });
  }

  openModal(message: string) {
    this.modalService.open(this.alert);
    this.contentModal = message;
  }

  get checkForm() {
    return Object.keys(this.formReset.controls).reduce((object: any, key: string) => {
      const formControl = this.formReset.get(key);
      object[key] = formControl?.errors && (formControl?.dirty || formControl?.touched);
      return object;
    }, {});
  }
}
