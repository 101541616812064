import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '../layouts/layout.module';
import { UserModule } from '../user/user.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { LoyaltyModule } from '../loyalty/loyalty.module';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    LayoutModule,
    UserModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CarouselModule,
    QRCodeModule,
    RouterModule,
    NgbModule,
    LoyaltyModule,
    LoyaltyIconModule,
    ReactiveFormsModule,
    ErrorsModule,
    ShortLongDescriptionModule,
    InlineSVGModule.forRoot(),
    FGBSharedModule,
  ],
})
export class PurchaseModule {}
