import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'ecash-success-page.component.html',
  styleUrls: ['ecash-success-page.component.scss'],
  selector: 'fgb-ecash-success',
})
export class EcashSuccessPageComponent implements OnInit {
  amounts$: Observable<number[]>;
  amount: number;
  autoTopUp: boolean = false;
  constructor() {}
  ngOnInit() {
  }
}
