<div class="banner">
  <div class="banner-logo"></div>
</div>

<ng-container *ifScreenSize="screenSize.Desktop">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</ng-container>

<!-- Filter options-->
<fgbcl-filter-modal
  [filterTypesList]="filterTypesList"
  (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
  [selectedValues]="selectedValues"
  (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
  [showOffCanvas]="showOffCanvas"
  (showOffCanvasEmitter)="handleShowOffCanvasEmitter($event)"
>
</fgbcl-filter-modal>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues"></fgbcl-mixed-loyalty-list>
</div>
