<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/logo.svg" draggable="false" />
    </a>
    <a class="nav-item" [routerLink]="['/payment']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.payment' | translate }}"
        >credit_card</span
      >
    </a>
    <a class="nav-item" [routerLink]="['/rewards']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.rewards' | translate }}"
        >redeem</span
      >
    </a>
    <!--Notifications-->
    <div class="nav-item">
      <fgb-notification-button></fgb-notification-button>
    </div>
    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown text-white">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="material-icons close-btn text-grey">cancel</i>
      </button>
      <!-- Logo -->
      <div class="mb-3">
        <img alt="" class="logo px-2 pt-3" src="assets/images/logo.png" draggable="false" />
      </div>
      <!-- Member Details -->
      <ng-container *ngIf="memberCard$ | async as memberCard">
        <div class="container text-white">
          <div class="mb-0 text-capitalize member-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </div>
          <div class="mb-3 mt-2 font-size-12">
            <span>{{ memberCard.ProductCode }} - </span>
            <span>{{ memberCard.MemberId }}</span>
          </div>
          <hr class="my-1 text-white w-100" />
          <div class="d-flex align-items-center my-2">
            <div class="card-points text-start" *ngIf="pointsBalance$">
              <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
              <div class="font-size-14">{{ 'member.card.points.balance' | translate }}</div>
            </div>
            <div class="balance-splitter mx-4"></div>
            <div class="card-points text-start" *ngIf="combinedCard$">
              <label class="font-size-14 mb-1">{{ combinedCard$ | async | FortressCurrencyPipe : 'GBP' }}</label>
              <div class="font-size-14">{{ 'member.card.ecash.balance' | translate }}</div>
            </div>
            <ng-container *ngIf="memberCard.DiscountLevel">
              <div class="balance-splitter mx-4"></div>
              <div class="card-discount-level text-start">
                <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
                <div class="font-size-14">{{ 'member.card.discount' | translate }}</div>
              </div>
            </ng-container>
          </div>
          <hr class="my-1 text-white w-100" />
        </div>
      </ng-container>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container mt-2">
        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/activity']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">history</span>
            <div class="nav-text fw-bold">{{ 'nav.activity' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a class="nav-item" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
          <a
            class="nav-item"
            [routerLink]="['/savings']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="nav-item-title-div">
              <span class="icon material-icons me-3" aria-hidden="true">sell</span>
              <div class="nav-text fw-bold">{{ 'nav.savings' | translate }}</div>
            </div>
            <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/payment']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">credit_card</span>
            <div class="nav-text fw-bold">{{ 'nav.payment' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/games/quiz']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/earn']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">emoji_events</span>
            <div class="nav-text fw-bold">{{ 'nav.earn' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/gallery']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">photo_library</span>
            <div class="nav-text fw-bold">{{ 'nav.gallery' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">email</span>
            <div class="nav-text fw-bold">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">article</span>
            <div class="nav-text fw-bold">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-3" aria-hidden="true">help</span>
            <div class="nav-text fw-bold">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item logout-link-div" (click)="logout()">
          <span class="icon material-icons me-3" aria-hidden="true">power_settings_new</span>
          <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
