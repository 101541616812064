import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss'],
  hostDirectives: [
    HideMobileNav
  ]
})
export class LottoDetailPageComponent implements OnInit {
  id: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
