<div class="full-page bg-primary w-100">
  <div class="col-12 register-panel">
    <div class="overflow-hidden p-0">
      <div class="row">
        <div class="col-lg-6 d-none d-lg-block login-banner"></div>
        <div class="d-flex justify-content-center d-lg-none bg-primary align-items-center logo-container">
          <img alt="" class="register-logo" src="assets/images/logo.svg" draggable="false" />
        </div>
        <div class="user-login-panel col-lg-6 col-12 text-start bg-white">
          <div class="container h-100">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
