<ng-container *ngIf="cardToken$ | async as cardToken; else addCard">
  <div
    class="ecash-button d-flex justify-content-between align-item-center border border-primary"
    [ngClass]="{
      'bg-frozen border-frozen': cardToken.Frozen
    }"
  >
    <div class="ecash-text fw-bold text-primary" [ngClass]="{ 'text-white': cardToken.Frozen }">
      {{ 'member.card.ecash' | translate }}
    </div>

    <div class="card-token-number" [ngClass]="{ 'text-white': cardToken.Frozen }">
      {{ cardToken?.CardNumber | creditCardNumber | slice : -9 }}
      <ng-container [ngSwitch]="cardToken!.CardType">
        <img class="payment-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
        <img class="payment-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
        <img class="payment-logo visa ms-2" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.png" />
        <div class="payment-logo" *ngSwitchDefault></div>
      </ng-container>
      <div class="payment-status text-success" *ngIf="!cardToken.Frozen">{{ 'member.card.ecash.active' | translate }}</div>
      <div class="payment-status text-white frozen-status" *ngIf="cardToken.Frozen">
        {{ 'member.card.ecash.frozen' | translate }}
      </div>

      <a class="d-flex text-primary" [ngClass]="{ 'text-white': cardToken.Frozen }" [routerLink]="'/payment'">
        <span class="icon material-icons">chevron_right</span>
      </a>
    </div>
  </div>
</ng-container>

<ng-template #addCard>
  <div class="ecash-button d-flex justify-content-between align-item-center border border-primary text-primary">
    <div class="ecash-text fw-bold">{{ 'member.card.ecash' | translate }}</div>
    <a class="btn d-flex align-item-center text-primary p-0" [routerLink]="'/payment'">
      <span class="icon material-icons pe-2">add_card</span>
      <span class="font-size-14 add-card-text">{{ 'member.card.ecash.add' | translate }}</span>
      <span class="icon material-icons ps-1">chevron_right</span>
    </a>
  </div>
</ng-template>
