<div class="d-none d-lg-flex banner">
  <div class="banner-logo"></div>
</div>

<div class="container d-lg-none d-block my-4">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container d-none d-lg-block">
  <fgbcl-ecash-description></fgbcl-ecash-description>

  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
</div>

<div class="container d-block d-lg-none">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>

  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>

<div class="container mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
