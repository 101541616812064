import { Component, OnInit } from '@angular/core';
import { CardToken, CardTokenQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-ecash-button',
  templateUrl: './ecash-button.component.html',
  styleUrls: ['./ecash-button.component.scss'],
})
export class EcashButtonComponent implements OnInit {
  cardToken$: Observable<CardToken | undefined>;

  constructor(private cardTokenQuery: CardTokenQuery) {}

  ngOnInit(): void {
    this.cardToken$ = this.cardTokenQuery.selectDefaultCard();
  }
}
