import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import {
  BalanceQuery,
  CartQuery,
  config,
  ErrorStateService,
  MarketplaceItemCart,
  MarketplaceItemStatus,
  MemberDetails,
  MemberQuery,
  MemberService,
  CardTokenQuery,
  MarketplaceService,
} from '@fgb/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddPaymentCardDetailComponent } from '@fgb/portal-component-library/src/lib/loyalty/add-payment-card-detail';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-cart-checkout-page',
  templateUrl: './cart-checkout-page.component.html',
  styleUrls: ['./cart-checkout-page.component.scss'],
})
export class CartCheckoutPageComponent implements OnInit, OnDestroy {
  totalPoints: number;
  totalItems$: Observable<number>;
  items$: Observable<MarketplaceItemCart[] | undefined>;
  editingMemberAddress: boolean = false;
  missingDetails: string = '';
  memberDetail$: Observable<MemberDetails | undefined>;
  memberAddressForm: UntypedFormGroup;
  portalId = '';
  hasSubmittedMemberAddress: boolean = false;
  memberDetails: MemberDetails;
  validCheckout: boolean;
  currentBalance: number;
  statusText: string;
  private _destroyed$ = new Subject<void>();
  modalRef: NgbModalRef;
  isSaveCardInfor: boolean = false;
  cardTokenCount: number = 0;
  cardInfor: any = {};
  listItems: any[] = [];
  convertListItems: any[] = [];
  isListHasMerchantItem: boolean = false;
  totalPriceMerchantValue: number = 0;
  orderId: string = '';

  constructor(
    private location: Location,
    private cartQuery: CartQuery,
    private errorService: ErrorStateService,
    private memberQuery: MemberQuery,
    private formBuilder: UntypedFormBuilder,
    private memberService: MemberService,
    private balanceQuery: BalanceQuery,
    private translate: TranslateService,
    private modalService: NgbModal,
    private cardTokenQuery: CardTokenQuery,
    private marketplaceService: MarketplaceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.errorService.clearErrors();

    this.cartQuery
      .updateBasketPointsTotal()
      .pipe(
        tap((val) => {
          this.totalPoints = val ? val : 0;
        })
      )
      .toPromise();

    this.cardTokenQuery
      .selectUnfrozenCards()
      .pipe(
        tap((tokens) => {
          if (tokens.length > 0) {
            this.cardTokenCount = tokens.length;
            this.cardInfor = { ...tokens[0] };
          }
        })
      )
      .toPromise();

    this.balanceQuery
      .selectPurse(config.purseConfig.virtualLoyalty)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((bal) => {
        this.currentBalance = bal ?? 0;
      });
    this.items$ = this.cartQuery.selectAllMarketplaceCartData().pipe(
      tap((items) => {
        this.validCheckout = this._validateItems(items);
        if (items) {
          const specificMerchantItem = items.find((item: MarketplaceItemCart) => item.productData.MerchantValue);
          if (specificMerchantItem && specificMerchantItem.productData.MerchantValue) {
            this.isListHasMerchantItem = true;
            this.totalPriceMerchantValue = items.reduce((prev, cur) => {
              return prev + cur.totalPrice;
            }, 0);
          }
          this.listItems = items;

          const orderBody = items.map((item: MarketplaceItemCart) => {
            return {
              Id: item.productData.Id,
              RewardItemId: item.productData.ProductItemId,
              GameNumber: item.productData.GameNumber,
              Quantity: item.purchaseQuantity,
              CustomFields: [],
            };
          });
          this.convertListItems = orderBody;

          this.marketplaceService.checkoutStart(orderBody).then((res) => {
            if (res.OrderId) {
              this.orderId = res.OrderId;
              this.marketplaceService.updateAdress(this.orderId, this.memberDetails);
              this.marketplaceService.updateShippingAdress(this.orderId, this.memberDetails);
            }
          });
        }
      })
    );
    this.totalItems$ = this.cartQuery.updateTotalItems();

    // get address from member details
    this.getMemberDetailAddress();
  }

  // go back to previous page from checkout page
  goBack() {
    this.location.back();
  }

  // make payment on checkout page, show error if member address data is invalid
  async payNow() {
    const res = await this.marketplaceService.completePurchaseBasketOrder(
      this.orderId,
      '/checkout',
      '/rewards/marketplace/purchase/success',
      !!this.cardInfor.CardNumber
    );

    const orderId = res?.Data?.OrderId || '';
    if (orderId) {
      this.router.navigate([`rewards/marketplace/purchase/success`], { queryParams: { purchaseOrderId: orderId } });
    }
  }

  /** Start editing the user's member details address. */
  editMemberAddress() {
    this.editingMemberAddress = true;
  }

  /** Clear the member details address form fields. */
  clearAddressForm(addressForm: UntypedFormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
      HomeNumber: '',
    });
  }

  // cancel button which will close and rebuild the form with the original data
  cancelAndRebuildForm() {
    this.editingMemberAddress = false;

    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  getMemberDetailAddress() {
    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  saveMemberDetailAddress() {
    this.hasSubmittedMemberAddress = true;

    if (this.memberAddressForm.valid) {
      this.memberDetails = { ...this.memberDetails, ...this.memberAddressForm.value };

      this.memberService.updateMemberDetails(this.portalId, this.memberDetails);
      this.marketplaceService.updateAdress(this.orderId, this.memberDetails);
      this.marketplaceService.updateShippingAdress(this.orderId, this.memberDetails);

      this.editingMemberAddress = false;
      this.errorService.clearErrors();
    }
  }

  // open modal add cart payment detail
  openCartAddPaymentDetailModal() {
    const formValue = this.memberAddressForm;
    this.modalRef = this.modalService.open(AddPaymentCardDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });
    this.modalRef.componentInstance.formValue = formValue;
    this.modalRef.componentInstance.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  saveCardInfor(isSave: boolean) {
    this.isSaveCardInfor = isSave;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _validateItems(items: MarketplaceItemCart[] | undefined) {
    if (!items || items.length === 0) {
      this.translate
        .stream('checkout.empty-basket')
        .pipe(takeUntil(this._destroyed$))
        .subscribe((data: string) => {
          this.statusText = data;
        });
      return false;
    }

    for (let item of items) {
      switch (item.productData.Status) {
        case MarketplaceItemStatus.SoldOut:
          this.translate
            .stream('marketplace-details.sold-out')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.NotEnoughPoints:
          this.translate
            .stream('marketplace-details.not-enough')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.ComingSoon:
          this.translate
            .stream('marketplace-details.coming-soon')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.MemberLimitReached:
          this.translate
            .stream('marketplace-details.limit-reached')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
      }
    }

    this.translate
      .stream('checkout-claim-now')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        this.statusText = data;
      });
    return true;
  }
}
