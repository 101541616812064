<div class="banner" role="banner">
  <div class="banner-logo"></div>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="polls$ | async as polls">
    <div *ngIf="!polls.length">
      <div class="w-100 text-center no-items my-5">
        <div class="no-results-placeholder-description card border">
          {{ 'polls.page.placeholder' | translate }}
        </div>
      </div>
    </div>
    <div *ngFor="let poll of polls">
      <fgb-survey-item [survey]="poll"></fgb-survey-item>
    </div>
  </ng-container>
</div>
