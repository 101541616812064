<div class="px-3">
  <div class="predicition-score font-size-14 fw-bold">{{ homeScore }} - {{ awayScore }}</div>
  <hr class="separator my-2" />
  <!--Correct Heading-->
  <div class="score-prediction pb-4 pt-lg-0 pt-1" *ngIf="hasSelectedCorrect">
    <div class="text-success fw-bold font-size-14">{{ 'score.predictor.completed.prediction.correct' | translate }}</div>
    <div class="font-size-12 px-2 pb-1 pb-lg-0 score-predictor-footer-text">
      {{ 'score.predictor.completed.prediction.correct.you.won.text' | translate }} {{ survey.PointValue }} Points! <br />{{
        'score.predictor.completed.prediction.correct.process.time' | translate
      }}
    </div>
  </div>
  <!--Incorrect Heading-->
  <div class="score-prediction pb-4 pt-lg-0 pt-1" *ngIf="!hasSelectedCorrect">
    <div class="text-danger fw-bold font-size-14">{{ 'score.predictor.completed.prediction.incorrect' | translate }}</div>
    <div
      class="font-size-12 px-2 pb-1 pb-lg-0 score-predictor-footer-text"
      [innerHTML]="'score.predictor.completed.prediction.incorrect.text' | translate | markdownTranslate"
    ></div>
  </div>
</div>
