<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="card p-3 rounded">
    <div class="d-flex">
      <img src="/assets/images/widgets/cashback.png" class="cashback-image" />

      <div class="cashback-text font-size-12 pt-2 ps-3">
        {{ 'cashback.widget.description' | translate }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-2">
      <div class="font-size-10 fw-bold">{{ 'cashback.widget.disclaimer' | translate }}</div>
      <a class="d-flex align-items-center fw-bold font-size-14 text-primary" [routerLink]="['/payment']"
        >{{ 'widget.learn.more' | translate }}<span class="icon material-icons">chevron_right</span></a
      >
    </div>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="card bg-primary p-3 rounded">
    <img src="/assets/images/widgets/cashback-transparent.svg" class="cashback-image" />
    <div class="d-flex justify-content-center">
      <a
        class="d-flex align-items-center text-white fw-bold text-center font-size-14 ps-3 mobile-link"
        [routerLink]="['/payment']"
        >{{ 'widget.learn.more' | translate }} <span class="icon material-icons">chevron_right</span></a
      >
    </div>
  </div>
</ng-container>
