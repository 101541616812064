<div class="survey-answer row my-3">
  <div class="survey-answer-text-container mx-auto mb-lg-5 mb-4 mt-lg-5 mt-3">
    <ng-container *ngIf="hasSelectedCorrect; else incorrect">
      <div class="text-success">
        <div>
          <ng-container *ngIf="pointsWon; else noPoints">
            <div class="text-center fw-bold font-size-14">Correct Answer !</div>
            <div class="text-center points-won fw-bold">+{{ pointsWon }} Points</div>
          </ng-container>
          <ng-template #noPoints>
            <div class="fw-bold font-size-14">Correct Answer !</div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #incorrect>
      <div class="text-danger">
        <div>
          <div class="text-center fw-bold font-size-14">Wrong Answer !</div>
          <div class="text-center points-won fw-bold">Better luck next time</div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="survey-answer-container w-100" [ngClass]="hasSelectedCorrect ? 'justify-content-center' : 'justify-content-around'">
    <div
      class="survey-answer-item m-2"
      [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
      *ngFor="let answer of selectedAnswers"
    >
      <div class="image-container">
        <img src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
      </div>
      <div class="answer-text text-center text-white">
        <span>{{ answer.Title }}</span>
      </div>
    </div>
    <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
      <div class="image-container">
        <img src="{{ correctAnswer.ImageURL | contentImage: '7' }}" alt="{{ correctAnswer.ImageURL }}" />
      </div>
      <div class="answer-text text-center text-center text-white">
        <span>{{ correctAnswer.Title }}</span>
      </div>
    </div>
  </div>
</div>
