<div class="d-none d-lg-flex banner">
  <div class="banner-logo"></div>
</div>

<div class="container d-lg-none d-block my-4">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
