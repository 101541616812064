<div class="d-block w-100 px-3">
  <div *ngIf="!canEdit" class="score-prediction">
    <hr class="separator my-2" />
    <div class="text-blue fw-bold font-size-14">{{ 'score.predictor.completed.good.luck.text' | translate }}</div>
    <div
      class="font-size-12 text-black pb-1 pb-lg-0 score-predictor-footer-text"
      [innerHTML]="'score.predictor.completed.submitted.text' | translate | markdownTranslate"
    ></div>
  </div>
  <div *ngIf="canEdit" class="score-prediction">
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary font-size-14 fw-bold" (click)="editAnswer.emit()">
        {{ 'score.predictor.completed.change.button' | translate }}
      </button>
    </div>
  </div>
</div>
