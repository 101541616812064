<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <div class="banner-logo"></div>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<div class="container my-4" *ifScreenSize="screenSize.Desktop">
  <div class="row">
    <div class="col-6">
      <fgb-member-card></fgb-member-card>
    </div>
    <div class="col-6">
      <fgb-vouchers></fgb-vouchers>
    </div>
  </div>
</div>

<div class="container mt-3">
  <div class="row">
    <div class="col-6 mb-4"><fgb-cash-back-widget></fgb-cash-back-widget></div>
    <div class="col-6 mb-4"><fgb-savings-widget></fgb-savings-widget></div>
    <div class="col-12" *ifScreenSize="screenSize.Mobile">
      <fgb-vouchers></fgb-vouchers>
    </div>
  </div>
</div>

<!--Scorecard carousel-->
<fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>

<div class="container">
  <!-- Featured Rewards -->
  <fgbcl-featured-rewards></fgbcl-featured-rewards>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>
