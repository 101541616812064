<div class="card border border-disabled">
  <div class="card-content px-3">
    <h5 class="mb-3 font-weight-bold text-primary text-capitalize">
      {{ 'voucher.title' | translate }}
    </h5>
    <span class="input-group pb-2">
      <input
        type="text"
        [(ngModel)]="voucherCode"
        placeholder="Insert Code Here"
        class="form-control"
        (keyup.enter)="redeemVoucher()"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary icon text-white font-weight-bold material-icons py-0"
          type="button"
          (click)="redeemVoucher()"
          [disabled]="disableButton"
        >
          arrow_forward_ios
        </button>
      </div>
    </span>
    <div class="result-box pt-1">
      <div class="result mt-0">
        <div *ngIf="errorService.hasErrors()" class="font-size-12">
          <p *ngFor="let error of errorService.errors" class="error-message">{{ error | translate }}</p>
        </div>
        <div class="font-size-12" *ngIf="redemptionResult" [ngClass]="{ 'text-success': sucessfulRedemption }">
          {{ redemptionResult }}
        </div>
      </div>
    </div>
  </div>
</div>
