import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { NumberIncrementInputComponent } from "./number-increment-input.component";

@NgModule({
    declarations: [
        NumberIncrementInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FGBSharedModule
    ],
    exports: [
        NumberIncrementInputComponent
    ]
})
export class NumberIncrementInputModule{}