<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a
    attr.aria-label="{{ 'rewards.nav.featured.screenreader' | translate }}"
    class="nav-item"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon material-icons" aria-hidden="true">redeem</div>
    <label>{{ 'rewards.nav.rewards' | translate }} </label>
  </a>

  <a
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate : { fgbdefault: 'Move to Wallet page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</nav>
