<div class="survey-answer">
  <div
    class="survey-answer-container py-1"
    [ngClass]="{
      'text-answer': survey.AnswerDisplayType == answerDisplayType.Text,
      'mx-lg-5': survey.SurveyType === surveyType.Poll
    }"
  >
    <div class="p-0 rounded-4 {{ margins }} {{ colDisplayType }} {{ boxBorder }}" *ngFor="let answer of survey.SurveyAnswers">
      <div
        class="survey-answer-item cursor-pointer"
        (click)="selectAnswer(answer.SurveyAnswerId)"
        [ngClass]="{
          selected: selectedAnswers.includes(answer.SurveyAnswerId),
          'options-box-border': survey.SurveyType === surveyType.Trivia,
          'w-100': survey.SurveyType === surveyType.Predictor,
          'w-90': survey.SurveyType === surveyType.Poll,
          'predictor-image-and-text':
            survey.AnswerDisplayType == answerDisplayType.ImageAndText && survey.SurveyType === surveyType.Predictor,
          'survey-answer-image-item':
            survey.AnswerDisplayType == answerDisplayType.Image || survey.AnswerDisplayType == answerDisplayType.ImageAndText,
          'survey-answer-image-item':
            survey.AnswerDisplayType == answerDisplayType.ImageAndText && survey.SurveyType === surveyType.Trivia
        }"
      >
        <ng-container [ngSwitch]="survey.AnswerDisplayType">
          <!-- Text with Image -->
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <div *ngIf="this.imgDisplayType === 'Portrait'">
              <div class="image-container border-0 border-bottom">
                <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              </div>
              <div class="answer-text text-center bg-white">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
            <div *ngIf="this.imgDisplayType === 'Landscape'" class="d-flex flex-row">
              <img
                class="image-container icon rounded-start-4"
                src="{{ answer.ImageURL | contentImage : '7' }}"
                alt="{{ answer.ImageURL }}"
              />
              <div class="answer-text-landscape text-center bg-white rounded-end-4 border-start-0 options-box-border">
                {{ answer.Title }}
              </div>
            </div>
          </ng-container>
          <!-- Text Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="answer-text text-center px-3">
              <span>{{ answer.Title }}</span>
            </div>
          </ng-container>
          <!-- Image Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Image">
            <div class="image-container rounded-4">
              <img
                [ngClass]="{
                  predictorSelected: survey.SurveyType === surveyType.Predictor
                }"
                src="{{ answer.ImageURL | contentImage : '7' }}"
                alt="{{ answer.ImageURL }}"
              />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
