import { Component, OnInit } from '@angular/core';
import { OtherMediaAuthService } from '@fgb/core';

@Component({
  selector: 'fgb-other-media-user-login',
  templateUrl: './other-media-user-login.component.html',
  styleUrls: ['./other-media-user-login.component.scss'],
})
export class OtherMediaUserLoginComponent implements OnInit {
  constructor(private otherMediaAuthService: OtherMediaAuthService) {}

  ngOnInit() {}

  loginOtherMedia() {
    this.otherMediaAuthService.login().toPromise();
  }
}
