import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VouchersComponent } from './components/vouchers/vouchers.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';

@NgModule({
  declarations: [VouchersComponent],
  exports: [VouchersComponent],
  imports: [CommonModule, FormsModule, SharedModule, NgbModule, ErrorsModule, FGBSharedModule],
})
export class VouchersModule {}
