<div class="banner-checkout d-none d-lg-flex">
  <h5 class="text-white">Checkout</h5>
</div>
<div class="container">
  <div class="back-mobile-bar d-flex justify-content-between align-items-center d-lg-none my-2">
    <!-- Back Button -->
    <button class="btn text-primary fw-bold d-block d-lg-none ps-0 go-back-btn" (click)="goBack()">
        <span class="font-size-14 text-go-back">{{ 'cart.goBack' | translate }}</span>
    </button>
    <!-- Checkout Title -->
    <div class="">
      <h4 class="mb-0 text-black font-size-14">Checkout</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-12 mt-3">
      <h4 class="text-black fw-bold font-size-14">{{ 'cart.itemsSummary' | translate }}</h4>
      <div class="mb-4 mt-4">
        <fgbcl-cart-item [isCheckoutPage]="true"></fgbcl-cart-item>
      </div>
      <button class="btn text-primary fw-bold d-none d-lg-block ps-0 go-back-btn" (click)="goBack()">
        <span class="font-size-14 text-go-back">{{ 'cart.goBack' | translate }}</span>      </button>
    </div>
    <div class="col-lg-5 col-12 mt-3">
      <h4 class="d-none d-lg-block">&nbsp;</h4>
      <!--member details address-->
      <div class="container address-container py-2 mb-3" [ngClass]="{ 'd-none': editingMemberAddress }">
        <div class="position-relative rounded p-2">
          <div class="d-flex justify-content-between">
            <h4 class="text-secondary font-size-14">Address</h4>
          </div>
          <div class="row">
            <div class="col-10">
              <span *ngIf="memberDetail$ | async as member" class="font-size-14 mb-0 d-inline-block text-info">
                <div>{{ member.Street }},</div>
                <div>{{ member.Town }},</div>
                <div>{{ member.County }},</div>
                <div>{{ member.Country }},</div>
                <div>{{ member.PostCode }},</div>
                <div>{{ member.HomeNumber }}</div>
              </span>
            </div>
            <div class="col-2 text-end">
              <a class="cursor-pointer" (click)="editMemberAddress()"
                ><span class="text-black material-icons align-middle">edit</span></a
              >
            </div>
          </div>
        </div>
      </div>
      <!--EO member details address view-->

      <!-- EDIT MEMBER DETAILS ADDRESS FORM -->
      <ng-container *ngIf="editingMemberAddress">
        <div class="container address-container mb-3">
          <div class="position-relative p-2">
            <div class="d-flex justify-content-between">
              <h4 class="text-secondary font-size-14 pt-2 mb-3">Address</h4>
              <a
                (click)="clearAddressForm(memberAddressForm)"
                class="text-primary heading-text-one mb-1 pt-2 font-size-14 cursor-pointer fw-bold"
                >Clear Form</a
              >
            </div>

            <form [formGroup]="memberAddressForm" class="font-size-12">
              <!--Street-->
              <div class="form-group">
                <label for="Street">
                  <span>Street</span>
                </label>
                <input
                  id="Street"
                  class="form-control"
                  name="Street"
                  type="text"
                  formControlName="Street"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Street.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid street.</div>
              </div>
              <!--City-->
              <div class="form-group">
                <label for="Town">
                  <span>City</span>
                </label>
                <input
                  id="Town"
                  class="form-control"
                  name="Town"
                  type="text"
                  formControlName="Town"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Town.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid city.</div>
              </div>
              <!--Country and region-->
              <fgbcl-country-and-regions
                [SeparateRow]="true"
                [Form]="memberAddressForm"
                [HasSubmitted]="hasSubmittedMemberAddress"
              ></fgbcl-country-and-regions>
              <!--Zip code-->
              <div class="form-group">
                <label for="Postcode">
                  <span>Zip Code</span>
                </label>
                <input
                  id="Postcode"
                  class="form-control"
                  name="Postcode"
                  type="text"
                  formControlName="PostCode"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.PostCode.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid zip code.</div>
              </div>
              <!--Phone-->
              <div class="form-group">
                <label for="Country">
                  <span>Phone Number</span>
                </label>
                <input
                  id="HomeNumber"
                  class="form-control"
                  name="HomeNumber"
                  type="text"
                  formControlName="HomeNumber"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.HomeNumber.errors
                  }"
                  required
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  minlength="8"
                  maxlength="11"
                />
                <div class="invalid-feedback">Please enter a valid phone number.</div>
              </div>
            </form>

            <div class="d-flex justify-content-between my-2 font-size-14">
              <button class="btn btn-primary fw-bold btn-cancel-save font-size-14" (click)="cancelAndRebuildForm()">Cancel</button>
              <button class="btn btn-success fw-bold btn-cancel-save font-size-14" (click)="saveMemberDetailAddress()">Save</button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--EO edit member details address-->
      <!--summary of items in basket-->
      <ng-container>
        <div class="container address-container p-3">
          <h4 class="text-black fw-bold font-size-14">{{'cart.summary' | translate}}</h4>
          <div class="row">
            <div class="col-6 fw-bold font-size-12"><span>{{'cart.summary.items' | translate}}</span></div>
            <div class="col-6 font-size-12 text-end pr-4">{{ totalItems$ | async }}</div>
          </div>
          <hr class="w-100" />
          <div class="d-flex justify-content-between align-items-center">
            <ng-container *ngIf="isListHasMerchantItem">
              <div class="fw-bold font-size-12">{{'cart.summary.totalMerchant' | translate}}</div>
              <div class="d-flex align-items-center">
                <div
                  class="col-6 fw-bold font-size-12 text-end">${{ (totalPriceMerchantValue / 100).toFixed(2) }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!isListHasMerchantItem">
              <div class="fw-bold font-size-12">{{'cart.summary.totalPoint' | translate}}</div>
              <div
                class="col-6 fw-bold font-size-12 text-end">{{ totalPoints | FortressCurrencyPipe: 'PTS' }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!cardInfor.CardNumber">
        <div class="payment-type-container">
        <p class="title">{{ 'cart.payment_type' | translate }}</p>

        <button class="payment-btn w-100" (click)="openCartAddPaymentDetailModal()">
          {{ 'cart.payment_button' | translate }}
        </button>

        <div class="checkbox-container d-flex justify-content-center align-items-center">
          <input type="checkbox" id="checkbox" class="checkbox" />
          <p class="description">{{ 'cart.save_payment_card' | translate }}</p>
        </div>
      </div>
      </ng-container>
      <ng-container *ngIf="cardInfor.CardNumber">
        <div class="payment-type-container">
          <div class="d-flex justify-content-between align-items-center">
            <p class="title">{{ 'cart.payment_type' | translate }}</p>

<!--            <p class="change-card">{{ 'cart.change_card_payment' | translate }}</p>-->
          </div>

          <div class="card-infor">
            <p class="font-size-14">{{'cart.card_infor' | translate }}</p>
            <p class="card-number">{{ cardInfor.CardNumber | creditCardNumber }}</p>
          </div>
        </div>
      </ng-container>

      <div class="px-0 mt-2" *ngIf="items$ | async as items">
        <button
          class="btn btn-success fw-bold mt-2 w-100 paynow-btn"
          (click)="payNow()"
          [disabled]="!validCheckout || totalPoints > currentBalance || !cardInfor.CardNumber"
        >
          {{ statusText }}
        </button>
      </div>
      <fgbcl-errors></fgbcl-errors>
    </div>
  </div>
</div>
