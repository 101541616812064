<div class="full-page w-100 d-lg-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-lg-block d-none" draggable="false" src="assets/images/login-registration/login-banner.jpg" />
  <div class="bg-primary d-flex w-100 d-lg-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div
      class="text-black fw-bold panel-text mlb-text font-size-14 text-center"
      [innerHTML]="'login.page.description' | translate | markdownTranslate"
    ></div>
    <fgb-other-media-user-login></fgb-other-media-user-login>
  </div>
</div>
