<!-- Back Button -->
<div class="container">
  <div class="text-danger pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards/auctions'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">Back </span>
  </div>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/auctions'" [backButtonTitle]="'Auctions'"></fgb-points-summary-bar>
</div>

<!-- Auction Details -->
<fgbcl-auction-details [auctionId]="id"></fgbcl-auction-details>
