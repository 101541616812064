<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card rounded mb-3 mb-lg-0 text-white p-4">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-start">
        <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>

        <div class="d-flex font-size-10">
          <div>{{ memberCard.ProductCode }}</div>
          <div class="px-1">-</div>
          <div>{{ memberCard.MemberId }}</div>
        </div>
      </div>
      <!--Balances-->
      <div class="d-flex justify-content-start align-items-center">
        <div class="card-points text-start border-white pe-4" *ngIf="pointsBalance$">
          <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>

        <div class="card-id text-start border-start ps-4" *ngIf="ecash$">
          <label class="font-size-14 mb-1">{{ ecash$ | async | FortressCurrencyPipe : 'GBP' }}</label>
          <div class="font-size-12">{{ 'member.card.credit.balance' | translate }}</div>
        </div>

        <div class="card-id text-start border-start-discount ps-4" *ngIf="memberCard.DiscountLevel">
          <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <!--eCash Button -->
  <fgb-ecash-button></fgb-ecash-button>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
