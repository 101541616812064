import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Survey, ScorePredictorStatus } from '@fgb/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'fgb-score-predictor-body',
  templateUrl: './score-predictor-body.component.html',
  styleUrls: ['./score-predictor-body.component.scss'],
})
export class ScorePredictorBodyComponent implements OnInit, OnDestroy {
  @Input() survey: Survey;
  @Input() status: ScorePredictorStatus;
  @Output() countDownComplete = new EventEmitter<null>();

  @Input() homeTeam: number;
  @Input() homeTeamValue: number;
  @Output() homeTeamValueChange = new EventEmitter<number>();

  @Input() awayTeam: number;
  @Input() awayTeamValue: number;
  @Output() awayTeamValueChange = new EventEmitter<number>();

  @Input() hasSelectedCorrect: boolean;
  @Input() canEditAnswer: boolean;
  scorePredictorStatus = ScorePredictorStatus;
  scoreForm: UntypedFormGroup;
  private _unsubscribeAll = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.scoreForm = this.fb.group({
      home: [this.homeTeamValue],
      away: [this.awayTeamValue],
    });

    this.scoreForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ home, away }) => {
      if (this.homeTeamValue != home) {
        this.homeTeamValueChange.emit((this.homeTeamValue = home));
      }
      if (this.awayTeamValue != away) {
        this.awayTeamValueChange.emit((this.awayTeamValue = away));
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
