<div
  class="details"
  [ngClass]="sameLine ? 'text-center d-flex justify-content-around align-items-baseline sticky-cta mt-0 mt-lg-4' : 'ms-3 ms-lg-0'"
  [ngSwitch]="buttonState"
  *ngIf="!isGamePending"
>
  <ng-container *ngSwitchCase="'player-link'">
    <div class="question-text pe-1">
      <span class="text-primary fw-bold d-block d-lg-none font-size-12">{{ selectedPlayer.PlayerName }}</span>
      <span class="text-primary fw-bold d-none d-lg-block font-size-12">{{ selectedPlayer.PlayerName }}</span>
    </div>
    <button
      class="btn btn-primary btn-select fw-bold mb-2 rounded-4"
      [routerLink]="['/games/pickaplayer/' + game.GameId]"
      routerLinkActive="router-link-active"
    >
      {{ 'pick.player.change.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'no-player-link'">
    <div class="question-text text-primary fw-bold font-size-12">{{ 'pick.player.no.player.selected' | translate }}</div>
    <button
      class="btn btn-success btn-select text-white fw-bold w-100 mb-2 rounded-4"
      [routerLink]="['/games/pickaplayer/' + game.GameId]"
      routerLinkActive="router-link-active"
    >
      {{ 'pick.player.select.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'make-selection'">
    <div class="question-text text-primary fw-bold pe-1 font-size-14">
      {{ 'pick.player.no.player.selected' | translate }}
    </div>
    <button class="btn btn-success btn-select btn-submit text-white fw-bold mb-2 rounded-4" [disabled]="true">
      {{ 'pick.player.submit.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'changing-player'">
    <div class="question-text pe-1">
      <span class="text-primary fw-bold">{{ selectedPlayer.PlayerName }}</span>
    </div>
    <button class="btn btn-primary btn-select fw-bold mb-2 rounded-4" (click)="buttonClick()">
      {{ 'pick.player.change.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'confirm'">
    <div class="question-text font-size-14 pe-1">
      <span class="text-primary fw-bold d-block d-lg-none">{{ selectedPlayer.PlayerName }}</span>
      <span class="text-primary fw-bold d-none d-lg-block">{{ selectedPlayer.PlayerName }}</span>
    </div>

    <button class="btn btn-success btn-select btn-submit text-white fw-bold mb-2 rounded-4" (click)="buttonClick()">
      {{ 'pick.player.submit.button' | translate }}
    </button>
  </ng-container>
</div>
