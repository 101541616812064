import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionLabels, MarketplaceExtendedQuery, MarketplaceItemExtended, MarketplaceService } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { tap, pluck, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
  hostDirectives: [
    HideMobileNav
  ]
})
export class MarketplaceDetailPageComponent implements OnInit {
  id: number;
  actionLabels = ActionLabels;
  marketplaceItem$: Observable<MarketplaceItemExtended | undefined>;
  marketplaceItemType: { backLink: string; type?: string; group?: string } = { backLink: '/rewards/marketplace' };
  destroyed$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(pluck('params'), takeUntil(this.destroyed$)).subscribe((param: any) => {
      this.id = param?.id;
      this.fetchMarketplace();
    });
  }

  fetchMarketplace() {
    firstValueFrom(this.marketplaceService.fetchMarketplaceItemById(this.id)).then(() => {
      this.marketplaceItem$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItem(this.id).pipe(
        tap((item) => {
          if (item) {
            this.marketplaceItemType = this.marketplaceExtendedQuery.getMarketplaceItemType(item);
          }
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
