<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="card p-3 rounded">
    <div class="d-flex">
      <img src="/assets/images/widgets/savings.png" class="savings-image" />
      <div class="right-side">
        <div class="savings-text font-size-14 pt-2 ps-3">
          {{ 'savings.widget.desc' | translate }}
        </div>
        <div class="d-flex justify-content-center">
          <a class="d-flex align-items-center fw-bold savings-link font-size-14 text-primary" [routerLink]="['/savings']"
            >{{ 'widget.learn.more' | translate }} <span class="icon material-icons">chevron_right</span></a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="card bg-primary p-3 rounded">
    <img src="/assets/images/widgets/savings-transparent.svg" class="savings-image" />
    <div class="d-flex justify-content-center">
      <a
        class="d-flex align-items-center text-white fw-bold text-center font-size-14 ps-3 savings-mobile-link"
        [routerLink]="['/savings']"
        >{{ 'widget.learn.more' | translate }} <span class="icon material-icons">chevron_right</span></a
      >
    </div>
  </div>
</ng-container>
