<nav class="nav nav-secondary account-details-nav">
  <!--Activity-->
  <a
    class="nav-item"
    [routerLink]="['/activity']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.activity.screenreader' | translate : { fgbdefault: 'Move to Activity page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">history</div>
    <label>{{ 'account.nav.activity' | translate }}</label>
  </a>

  <!--Payment-->
  <a
    class="nav-item"
    [routerLink]="['/payment']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.payment.screenreader' | translate : { fgbdefault: 'Move to payment page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">credit_card</div>
    <label>{{ 'nav.payment' | translate }}</label>
  </a>
</nav>
