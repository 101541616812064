<div class="full-page container-fluid w-100 p-0">
  <div class="p-0">
    <div class="row m-0 vh-100">
      <div class="col-12 col-lg-6 p-0 login-banner">
        <img src="/assets/images/login-registration/jets-login-banner.jpg" alt="" />
      </div>
      <div class="col-12 user-login-panel col-lg-6 text-start bg-white">
        <div class="row h-100">
          <div class="col-12">
            <div class="panel-text text-center text-primary">
              <span class="material-icons"> engineering </span>
              <h5 class="mb-3">{{ 'maintenance.page.title' | translate }}</h5>
              <p class="text-black font-size-14" [innerHTML]="'maintenance.page.text' | translate | markdownTranslate"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
