import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "@fgb/portal-component-library/src/lib/pipes";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScorePredictorBodyComponent } from "./score-predictor-item/score-predictor-body/score-predictor-body.component";
import { ScorePredictorFooterComponent } from "./score-predictor-item/score-predictor-footer/score-predictor-footer.component";
import { ScorePredictorHeaderComponent } from "./score-predictor-item/score-predictor-header/score-predictor-header.component";
import { ScorePredictorItemComponent } from "./score-predictor-item/score-predictor-item.component";
import { ScorePredictorListComponent } from "./score-predictor-list/score-predictor-list.component";
import { ScorePredictorRulesComponent } from "./score-predictor-rules/score-predictor-rules.component";
import { SharedModule } from '../../../../shared/shared.module';
import { CountdownModule } from "@fgb/portal-component-library/src/lib/shared/countdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScorePredictorCompletedPendingComponent } from "./score-predictor-completed/score-predictor-completed-pending/score-predictor-completed-pending.component";
import { ScorePredictorCompletedProcessedComponent } from "./score-predictor-completed/score-predictor-completed-processed/score-predictor-completed-processed.component";
import { ScorePredictorCompletedComponent } from "./score-predictor-completed/score-predictor-completed.component";

import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { NumberIncrementInputModule } from "src/app/shared/components/number-increment-input/number-increment-input.module";
import { DirectivesModule } from "@fgb/portal-component-library/src/lib/directives";

@NgModule({
    declarations: [
        ScorePredictorRulesComponent,
        ScorePredictorListComponent,
        ScorePredictorItemComponent,
        ScorePredictorBodyComponent,
        ScorePredictorFooterComponent,
        ScorePredictorHeaderComponent,
        ScorePredictorCompletedComponent,
        ScorePredictorCompletedPendingComponent,
        ScorePredictorCompletedProcessedComponent
    ],
    imports: [
        NgbModule,
        CommonModule,
        CarouselModule,
        FormsModule,
        ReactiveFormsModule,
        FGBSharedModule,
        // Component Library Imports
        CountdownModule,
        PipesModule,
        DirectivesModule,
        // Local imports
        SharedModule,
        NumberIncrementInputModule
    ],
    exports: [
        ScorePredictorRulesComponent,
        ScorePredictorListComponent,
        ScorePredictorItemComponent,
        ScorePredictorBodyComponent,
        ScorePredictorFooterComponent,
        ScorePredictorHeaderComponent,
        ScorePredictorCompletedComponent,
        ScorePredictorCompletedPendingComponent,
        ScorePredictorCompletedProcessedComponent
    ]
})
export class ScorePredictorModule{}