import { Component, OnInit, Input } from '@angular/core';
import { Barcode, BarcodeQuery, MemberCardQuery, MemberDetails, MemberQuery, SeatingDetailQuery, SeatingDetail } from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { BalanceQuery } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() memberId: string;
  @Input() primaryTheme: boolean = false;
  @Input() showCashBackToggle: boolean = false;
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  seatingDetail$: Observable<SeatingDetail | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  ecash$: Observable<number | undefined>;
  pointsBalance$: Observable<number | undefined>;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private seatingDetailQuery: SeatingDetailQuery,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit() {
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
